import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import {
  ADMIN,
  CHURCH_MAINTENANCE_ENGINEER,
  CHURCH_MANAGER,
  DRONE_OPERATOR,
  MANAGER,
  ROLE_ADMIN,
  ROLE_BUSINESSMANAGER,
  ROLE_MAINTENANCEENGINEER,
  ROLE_CHURCHMANAGER,
  ROLE_DRONEOPERATOR,
  LANDING,
} from "../constants";

// Define an interface for the AuthContext value
interface AuthContextValue {
  user: any; // Replace with the actual type of your user object (e.g., User)
  login: (user: any) => void;
  logout: () => void;
}

// Create the AuthContext with the specified type
const AuthContext = createContext<AuthContextValue>({
  user: null,
  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = (user: any) => {
    setUser(user);
    // navigate to the home page after logging in considering the role of the user
    if (user.role === ROLE_CHURCHMANAGER) {
      navigate(CHURCH_MANAGER);
    } else if (user.role === ROLE_BUSINESSMANAGER) {
      navigate(MANAGER);
    } else if (user.role === ROLE_ADMIN) {
      navigate(ADMIN);
    } else if (user.role === ROLE_DRONEOPERATOR) {
      navigate(DRONE_OPERATOR);
    } else if (user.role === ROLE_MAINTENANCEENGINEER) {
      navigate(CHURCH_MAINTENANCE_ENGINEER);
    } else {
      navigate("/");
    }
  };

  // call this function when you want to log out the user
  const logout = () => {
    setUser(null);
    navigate(LANDING);
  };
  const value = useMemo(() => {
    return { user, login, logout };
  }, [login, logout, user]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
