import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { AddPhotoAlternate } from "@mui/icons-material";
import { useAuth } from "../../../hooks/useAuth";
import { BACKEND_APP_URL } from "../../../constants";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import MultipleFileUploader from "../../../utils/MultipleFileUploader";
import { useTheme } from "../../../ThemeProviderWrapper";

const Uploader = ({
  folderName,
  setFold,
  uploadModalIsOpen,
  handleUploadModalClose,
}: {
  folderName: string;
  setFold: React.Dispatch<React.SetStateAction<boolean>>;
  uploadModalIsOpen: boolean;
  handleUploadModalClose: () => void;
}) => {
  const auth = useAuth();
  const user = auth?.user;
  const [uploading, setUploading] = useState(false);
  const [startUpload, setStartUpload] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const { isDarkMode, toggleTheme } = useTheme();
  useEffect(() => {
    if (isDarkMode) {
      toggleTheme();
    }
  }, [isDarkMode, toggleTheme]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const images = e.target.files;
    if (images) {
      // Convert FileList to array
      const filesArray = Array.from(images);
      // add the files to state preserving any previous files
      setFiles([...files, ...filesArray]);
    }
  };

  const onSuccessFunction = () => {
    toast.success("Images saved successfully", {
      theme: "dark",
    });
    setFold(false);
    setUploading(false);
    handleUploadModalClose();
  };
  const onErrorFunction = () => {
    toast.error("Failed to save images", {
      theme: "colored",
    });
    setUploading(false);
  };

  return (
    <Dialog
      open={uploadModalIsOpen}
      onClose={handleUploadModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Grid container spacing={3}>
            <Typography
              variant="h4"
              color={"text.primary"}
              sx={{ fontWeight: 600, ml: 2 }}
            >
              Upload Images to {folderName}
            </Typography>
            <Grid item xs={12}>
              <Paper
                sx={{ p: 2, textAlign: "center", color: "text.secondary" }}
              >
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="contained-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <AddPhotoAlternate />
                  </IconButton>
                  <Typography variant="body1">Upload Images</Typography>
                </label>
              </Paper>
            </Grid>
            {files.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                }}
              >
                <Typography variant="h6" color="text.primary">
                  {files.length} files selected
                </Typography>
              </Box>
            )}
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setUploading(true);
                  setStartUpload(true);
                }}
                fullWidth
                disabled={files.length === 0 || uploading}
              >
                <Box sx={{ display: "flex", direction: "row", gap: 1 }}>
                  {uploading && <CircularProgress size={"20px"} />}
                  <Typography variant="body1">Save Images</Typography>
                </Box>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <MultipleFileUploader
                endPoint={`${BACKEND_APP_URL}/api/maintenance-engineer/gallery`}
                formKey="images"
                setStartUpload={setStartUpload}
                startUpload={startUpload}
                onSuccessfulUpload={onSuccessFunction}
                onFailedUpload={onErrorFunction}
                body={{
                  folder_name: folderName,
                  files: files,
                  manager_id: user.created_by,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Uploader;
