import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { useState } from "react";

const EmailSender = () => {
  const [sending, setSending] = useState(false);

  interface Inputs {
    name: string;
    user_email: string;
    message: string;
  }

  const schema = z.object({
    name: z.string().min(3, { message: "Name must be at least 3 characters" }),
    user_email: z.string().email(),
    message: z
      .string()
      .min(3, { message: "Message must be at least 3 characters" }),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      name: "",
      user_email: "",
      message: "",
    },
  });

  const onSubmit = async (inputs: Inputs) => {
    const data = {
      service_id: process.env.REACT_APP_SERVICE_ID,
      template_id: process.env.REACT_APP_TEMPLATE_ID,
      user_id: process.env.REACT_APP_USER_ID,
      template_params: {
        user_name: inputs.name,
        message: inputs.message,
        user_email: inputs.user_email,
      },
    };

    if (
      data.service_id === undefined ||
      data.template_id === undefined ||
      data.user_id === undefined ||
      data.template_params === undefined
    ) {
      return;
    }
    setSending(true);
    emailjs
      .send(
        data.service_id,
        data.template_id,
        data.template_params,
        data.user_id
      )
      .then(
        () => {
          toast.success("Email sent SUCCESS!", { autoClose: 3000 });
          reset();
          setSending(false);
        },
        (error) => {
          toast.error("Email sent FAILED...", { autoClose: 3000 });
          console.error(error);
          setSending(false);
        }
      )
      .catch((error) => {
        toast.error("Email sent FAILED...", { autoClose: 3000 });
        console.error(error);
        setSending(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#e1e1e1",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h3"
        color="#0099ff"
        sx={{ fontWeight: 600, textAlign: { xs: "center", md: "left" }, mb: 4 }}
      >
        Email Us
      </Typography>
      <Box
        sx={{
          p: 1,
          width: { xs: "100%", md: "45%" },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",

              gap: 1,
            }}
          >
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  variant="outlined"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                />
              )}
            />
            <Controller
              name="user_email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  variant="outlined"
                  fullWidth
                  error={!!errors.user_email}
                  helperText={
                    errors.user_email ? errors.user_email.message : ""
                  }
                />
              )}
            />
            <Controller
              name="message"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.message}
                  helperText={errors.message ? errors.message.message : ""}
                />
              )}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={sending || isSubmitting}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {sending && <CircularProgress size={20} />}
                Send Email
              </Box>
              {/* Send Email */}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default EmailSender;
