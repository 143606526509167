import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Paper, CssBaseline } from "@mui/material";
import Footer from "../Footer/Footer";
import background_image from "../../../assets/images/landing_page_bg.webp";
import background_image_sm from "../../../assets/images/landing_page_bg_sm.webp";
import { useTheme } from "../../../ThemeProviderWrapper";
import { TypographySection } from "./TypographySection";
import SectionOne from "./SectionOne";
import Heading from "./Heading";
import YouTubeSection from "./YouTubeSection";
import NavBar from "./NavBar";
import EmailSender from "./EmailSender";
import { ToastContainer } from "react-toastify";

const StyledBox = styled(Paper)({
  background: "white",
});

const LandingPage = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  const dimensions = {
    height: 1904,
    width: 3840,
  };

  const ratio = dimensions.height / dimensions.width;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [bgImage, setBgImage] = useState(background_image);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      if (window.innerWidth < 901) {
        setBgImage(background_image_sm);
      } else {
        setBgImage(background_image);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      toggleTheme();
    }
  }, [isDarkMode, toggleTheme]);

  return (
    <StyledBox>
      <ToastContainer />
      <NavBar />
      <CssBaseline />
      <Heading />

      <Box
        sx={{
          pt: 1,
          pb: 4,
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          height: screenWidth * ratio,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            alignContent: "center",
            textAlign: "center",
            height: "100%",
            pt: 3,
          }}
        >
          <SectionOne width={screenWidth} />
        </Box>
      </Box>
      <TypographySection screenWidth={screenWidth} />
      <YouTubeSection />
      <EmailSender />
      <Footer />
    </StyledBox>
  );
};

export default LandingPage;
