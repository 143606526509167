import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import church from "../../../assets/images/church_req.webp";
import church_media from "../../../assets/images/gallery - church manager.png";
import church_comments from "../../../assets/images/Glass Analytic 1.png";
import { useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";
import {
  BACKEND_APP_URL,
  CHURCH_MAINTENANCE_ENGINEERS,
  COMMENTS,
  GALLERY,
} from "../../../constants";
import { AddPhotoAlternate, Close } from "@mui/icons-material";
import { useAuth } from "../../../hooks/useAuth";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { performRequestWithRetry } from "../../../utils/apiRetry";
import imageCompression from "browser-image-compression";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export function OngoingSection({ data, loading }: any) {
  const navigate = useNavigate();
  const [currentData, setCurrentData] = useState(data[0]);
  const [lastData, setLastData] = useState({} as any);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [lastImages, setLastImages] = useState<string[]>([]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [promotion_code, setPromotionCode] = useState("");
  const auth = useAuth();
  const user = auth.user;

  useEffect(() => {
    setCurrentData(data[0]);
    if (data.length > 1) {
      setLastData(data[1]);
    }
  }, [data, loading]);

  useEffect(() => {
    setImages(currentData?.images ? currentData?.images : []);
  }, [currentData]);

  useEffect(() => {
    setLastImages(lastData?.images ? lastData?.images : []);
  }, [lastData]);

  // const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const files = e.target.files;
  //   let imageSpace = 0;
  //   if (files) {
  //     const newImages: string[] = [];
  //     for (let i = 0; i < files.length; i++) {
  //       const file = files[i];
  //       imageSpace += file.size;
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         if (typeof reader.result === "string") {
  //           newImages.push(reader.result);
  //           if (newImages.length === files.length) {
  //             setImages((prevImages) => [...prevImages, ...newImages]);
  //           }
  //         }
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //   }
  // };

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      const newImages: string[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Compress the image
        const options = {
          maxSizeMB: 1, // Maximum size in MB
          maxWidthOrHeight: 1920, // Maximum width or height
          useWebWorker: true, // Use a web worker for better performance
        };

        try {
          const compressedFile = await imageCompression(file, options);
          const reader = new FileReader();

          reader.onload = () => {
            if (typeof reader.result === "string") {
              newImages.push(reader.result);
              if (newImages.length === files.length) {
                setImages((prevImages) => [...prevImages, ...newImages]);
              }
            }
          };

          reader.readAsDataURL(compressedFile);
        } catch (error) {
          console.error("Error compressing image:", error);
          toast.info("Try with a smaller images");
        }
      }
    }
  };
  const handleImageHover = (index: number) => {
    setHoveredIndex(index);
  };

  const handleImageClick = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = {
      ...currentData,
      church_manager_id: user.id,
      status: "Requested",
      images: images,
      duration: {
        start: new Date().toISOString().split("T")[0],
        end: new Date().toISOString().split("T")[0],
      },
      promotion_code,
    };
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/church-manager/requests`,
      "POST",
      formData,
      user,
      auth.logout
    ).then((response) => {
      if (response?.status === 200) {
        toast.success("Request created successfully");
      }
    });

    setOpen(false);
    setCurrentData(formData);
  };

  const makePayment = async () => {
    const stripe_public_key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    console.log(stripe_public_key);
    const stripe = await loadStripe(stripe_public_key as string);
    const body = {
      products: [
        {
          name: currentData.name,
          description: `Annual maintenance charges for the church ${currentData.name} from ${currentData.duration.start} to ${currentData.duration.end} \n - Requested by ${currentData.manager}`,
          price: currentData.cost,
          currency: "usd",
          quantity: 1,
          images: currentData.images,
        },
      ],
      email: currentData.email,
      success_url: "/church-manager/payment-success",
      cancel_url: "/church-manager/payment-cancel",
      metadata: {
        request_id: currentData.id,
      },
    };
    const response = await performRequestWithRetry(
      `${BACKEND_APP_URL}/api/payment/checkout-session`,
      "POST",
      body,
      user,
      auth.logout
    );
    const session = response?.data;
    const result = await stripe?.redirectToCheckout({
      sessionId: session.id,
    });
    if (result?.error) {
      console.error(result.error.message);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {currentData?.status === "New"
            ? "Create New Review Request"
            : "Review Request Details"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-name"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Church Name
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={currentData?.name ? currentData?.name : "N/A"}
                  disabled
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-address"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Church Address
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={
                    currentData?.address ? currentData?.address : "N/A"
                  }
                  disabled
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-email"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Email Address
                </FormLabel>
                <TextField
                  size="small"
                  type="email"
                  defaultValue={currentData?.email ? currentData?.email : "N/A"}
                  disabled
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-manager"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Church Manager
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={
                    currentData?.manager ? currentData?.manager : "N/A"
                  }
                  disabled
                />
              </FormControl>
              <Divider />
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-duration"
                  sx={{
                    minWidth: 120,
                  }}
                  required
                >
                  Sample Images
                </FormLabel>
                {currentData?.status === "New" && (
                  <IconButton
                    component="label"
                    color="info"
                    sx={{
                      width: 50,
                      height: 50,
                      borderRadius: 50,
                    }}
                    disabled={images.length >= 3}
                  >
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                      multiple={true}
                      max={3}
                    />
                    <AddPhotoAlternate />
                  </IconButton>
                )}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignContent: "flex-start",
                    gap: 2,
                  }}
                >
                  {images.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                      onMouseEnter={() => handleImageHover(index)}
                      onMouseLeave={() => setHoveredIndex(1000)}
                    >
                      {hoveredIndex === index &&
                        currentData?.status === "New" && (
                          <IconButton
                            onClick={() => handleImageClick(index)}
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <Close />
                          </IconButton>
                        )}
                      <img
                        key={index}
                        src={image}
                        alt={`Uploaded ${index}`}
                        style={{ maxWidth: 60, maxHeight: 60 }}
                      />
                    </div>
                  ))}
                </Box>
              </FormControl>

              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-price"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Promotion Code
                </FormLabel>
                <TextField
                  size="small"
                  type={"text"}
                  value={
                    currentData?.promotion_code
                      ? currentData.promotion_code
                      : promotion_code
                  }
                  onChange={(e) => {
                    setPromotionCode(e.target.value);
                  }}
                  disabled={currentData?.status !== "New"}
                />
              </FormControl>

              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-price"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Annual Cost
                </FormLabel>
                <TextField
                  size="small"
                  type={currentData?.cost ? "number" : "text"}
                  defaultValue={currentData?.cost ? currentData?.cost : "TBD"}
                  disabled
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-status"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Status
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={
                    currentData?.status ? currentData?.status : "N/A"
                  }
                  disabled
                />
              </FormControl>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                {currentData?.status === "New" && (
                  <Button
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={images.length < 1}
                  >
                    Submit Request
                  </Button>
                )}
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog open={open2} onClose={() => setOpen2(false)}>
        <DialogTitle>Review Request Details</DialogTitle>
        <Divider />
        <DialogContent>
          <form>
            <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-name"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Church Name
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={lastData?.name ? lastData?.name : "N/A"}
                  disabled
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-address"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Church Address
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={lastData?.address ? lastData?.address : "N/A"}
                  disabled
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-email"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Email Address
                </FormLabel>
                <TextField
                  size="small"
                  type="email"
                  defaultValue={lastData?.email ? lastData?.email : "N/A"}
                  disabled
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-manager"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Church Manager
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={lastData?.manager ? lastData?.manager : "N/A"}
                  disabled
                />
              </FormControl>
              <Divider />
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-duration"
                  sx={{
                    minWidth: 120,
                  }}
                  required
                >
                  Sample Images
                </FormLabel>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignContent: "flex-start",
                    gap: 2,
                  }}
                >
                  {lastImages.map((image, index) => (
                    <div
                      key={index}
                      style={{ position: "relative", display: "inline-block" }}
                      onMouseEnter={() => handleImageHover(index)}
                      onMouseLeave={() => setHoveredIndex(1000)}
                    >
                      <img
                        key={index}
                        src={image}
                        alt={`Uploaded ${index}`}
                        style={{ maxWidth: 60, maxHeight: 60 }}
                      />
                    </div>
                  ))}
                </Box>
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-price"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Annual Cost
                </FormLabel>
                <TextField
                  size="small"
                  type={currentData?.cost ? "number" : "text"}
                  defaultValue={lastData?.cost ? lastData?.cost : "TBD"}
                  disabled
                />
              </FormControl>
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 3,
                  alignItems: "center",
                }}
              >
                <FormLabel
                  id="church-status"
                  sx={{
                    minWidth: 120,
                  }}
                >
                  Status
                </FormLabel>
                <TextField
                  size="small"
                  type="text"
                  defaultValue={lastData?.status ? lastData?.status : "N/A"}
                  disabled
                />
              </FormControl>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  gap: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setOpen2(false);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            variant="h4"
            color="text.primary"
            sx={{ fontWeight: 600 }}
          >
            Ongoing Review Request
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              m: 2,
            }}
          >
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  mb: 5,
                }}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
            {!loading && currentData?.status === "New" && (
              <Box>
                <Typography
                  variant="body2"
                  color="text.primary"
                  sx={{ fontWeight: 500, mb: 2 }}
                >
                  You can add a new review request for the upcoming year by
                  clicking the button below.
                </Typography>
                <Tooltip title="Add Review Request">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                    sx={{
                      width: 50,
                      height: 60,
                      borderRadius: 50,
                    }}
                  >
                    <Add fontSize="large" />
                  </Button>
                </Tooltip>
              </Box>
            )}

            {!loading && currentData?.status === "PaymentPending" && (
              <Box>
                <Typography
                  variant="body2"
                  color="text.primary"
                  sx={{ fontWeight: 500 }}
                >
                  Your review request is processed successfully. Please proceed
                  to pay the annual charges.
                </Typography>
              </Box>
            )}

            {((!loading && currentData?.status === "Requested") ||
              currentData?.status === "PaymentPending" ||
              currentData?.status === "Paid" ||
              currentData?.status === "Assigned") && (
              // only excluding expired requests
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Card sx={{ width: 500 }}>
                  <CardContent
                    sx={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Box
                      component="img"
                      src={
                        currentData?.images ? currentData?.images[0] : church
                      }
                      alt="random"
                      sx={{
                        maxWidth: 150,
                        maxHeight: 150,
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="text.primary"
                        sx={{ fontWeight: 600 }}
                      >
                        From {currentData?.duration?.start} to{" "}
                        {currentData?.duration?.end}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.primary"
                        sx={{ fontWeight: 500 }}
                      >
                        Requested by, {currentData?.manager}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 2,
                        }}
                      >
                        <Chip
                          label={currentData?.status}
                          color={
                            currentData?.status === "Assigned"
                              ? "success"
                              : "info"
                          }
                          sx={{ fontWeight: 700 }}
                          size="small"
                        />
                        <Typography
                          variant="body2"
                          color="text.primary"
                          sx={{ fontWeight: 600 }}
                        >
                          {currentData?.cost
                            ? "$".concat(Number(currentData?.cost).toFixed(2))
                            : "TBD"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 3,
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => setOpen(true)}
                        >
                          More Info
                        </Button>
                        {currentData?.status === "PaymentPending" && (
                          <Button
                            variant="contained"
                            color="success"
                            onClick={() => makePayment()} // Handle button click event
                          >
                            Proceed to Pay
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color="info"
            onClick={() => navigate(CHURCH_MAINTENANCE_ENGINEERS)}
            sx={{
              width: "100%",
            }}
          >
            <Typography
              variant="h6"
              color="text.background"
              sx={{ fontWeight: 600 }}
            >
              Church Maintanance Engineers
            </Typography>
          </Button>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              sx={{
                width: 200,
                height: 200,
                padding: 0,
                backgroundColor: "white",
                borderRadius: 10,
              }}
              variant="contained"
              color="info"
              onClick={() => navigate(COMMENTS)}
            >
              <Box
                component="img"
                src={church_comments}
                alt="random"
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 10,
                }}
              />
            </Button>
            <Button
              sx={{
                width: 200,
                height: 200,
                padding: 0,
                backgroundColor: "white",
                borderRadius: 10,
              }}
              variant="contained"
              color="info"
              onClick={() => navigate(GALLERY)}
            >
              <Box
                component="img"
                src={church_media}
                alt="random"
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 10,
                }}
              />
            </Button>
          </Box>
        </Box>
      </Box>
      {data.length > 0 && (
        <Box
          sx={{
            mt: 5,
          }}
        >
          <Typography
            variant="h4"
            color="text.primary"
            sx={{ fontWeight: 600 }}
          >
            Last Review Request
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              m: 2,
            }}
          >
            <Card sx={{ width: 500 }}>
              <CardContent
                sx={{ display: "flex", flexDirection: "row", gap: 5 }}
              >
                <Box
                  component="img"
                  src={lastData?.images ? lastData?.images[0] : church}
                  alt="random"
                  sx={{
                    maxWidth: 150,
                    maxHeight: 150,
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="text.primary"
                    sx={{ fontWeight: 600 }}
                  >
                    From {lastData?.duration?.start} to{" "}
                    {lastData?.duration?.end}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.primary"
                    sx={{ fontWeight: 500 }}
                  >
                    Requested by, {lastData?.manager}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 2,
                    }}
                  >
                    <Chip
                      label={lastData?.status}
                      color={
                        lastData?.status === "Assigned" ? "success" : "info"
                      }
                      sx={{ fontWeight: 700 }}
                      size="small"
                    />
                    <Typography
                      variant="body2"
                      color="text.primary"
                      sx={{ fontWeight: 600 }}
                    >
                      {lastData?.cost
                        ? "$".concat(Number(lastData?.cost).toFixed(2))
                        : "TBD"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 3,
                    }}
                  >
                    <Button variant="contained" onClick={() => setOpen2(true)}>
                      More Info
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
    </>
  );
}
