import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useMemo, useState } from "react";
import { BACKEND_APP_URL } from "../../constants";
import { useAuth } from "../../hooks/useAuth";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import AddNewMaintenanceEngineerFormDialog from "./AddNewMaintenanceEngineerFormDialog";
import EditMaintenanceEngineerFormDialog from "./EditMaintenanceEngineerFormDialog";
import { toast, ToastContainer } from "react-toastify";
import { performRequestWithRetry } from "../../utils/apiRetry";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.common.black,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function MaintenanceEngineersComponent() {
  const [maintenanceEngineers, setMaintenanceEngineers] = useState<
    {
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      password: string;
      role: string;
      created_at: string;
    }[]
  >([]);
  const [maintenanceEngineerId, setMaintenanceEngineerId] = useState("");
  const currentMaintenanceEngineer = useMemo(() => {
    return (
      maintenanceEngineers.find(
        (engineer) => engineer.id === maintenanceEngineerId
      ) || null
    );
  }, [maintenanceEngineerId, maintenanceEngineers]);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updated, setUpdated] = useState(false);

  const auth = useAuth();
  const user = auth.user;

  useEffect(() => {
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/maintenance-engineers/${user.id}`,
      "GET",
      null,
      user,
      auth.logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          setMaintenanceEngineers(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to fetch maintenance engineers");
      });
  }, [auth.logout, user, updated]);

  const handleClickOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = (
    maintenanceEngineer: {
      first_name: string;
      last_name: string;
      email: string;
      password: string;
      role: string;
    } | null
  ) => {
    if (maintenanceEngineer) {
      const reqBody = { ...maintenanceEngineer, created_by: user.id };
      performRequestWithRetry(
        `${BACKEND_APP_URL}/api/maintenance-engineers`,
        "POST",
        reqBody,
        user,
        auth.logout
      )
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("Maintenance Engineer added successfully", {
              theme: "dark",
            });
            setUpdated(!updated);
          } else {
            toast.error("Failed to add maintenance engineer");
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.response.data.message === "Email already exists.") {
            toast.error("Email already exists.");
          } else {
            toast.error("Failed to add maintenance engineer", {
              theme: "colored",
            });
          }
        });
    }
    setOpenAddDialog(false);
  };

  const handleClickOpenDeleteDialog = (maintenanceEngineerID: string) => {
    setMaintenanceEngineerId(maintenanceEngineerID);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = (agree: boolean) => {
    if (agree) {
      performRequestWithRetry(
        `${BACKEND_APP_URL}/api/maintenance-engineers/${maintenanceEngineerId}`,
        "DELETE",
        null,
        user,
        auth.logout
      ).then((response) => {
        if (response && response.status === 200) {
          toast.success("Maintenance Engineer deleted successfully", {
            theme: "dark",
          });
          let new_rows = maintenanceEngineers.filter(
            (row) => row.id !== maintenanceEngineerId
          );
          setMaintenanceEngineers(new_rows);
        } else {
          toast.error("Failed to delete maintenance engineer", {
            theme: "colored",
          });
        }
      });
    }
    setMaintenanceEngineerId("");
    setOpenDeleteDialog(false);
  };

  const handleClickOpenEditDialog = (maintenanceEngineerID: string) => {
    setMaintenanceEngineerId(maintenanceEngineerID);
    setOpenEditDialog(true);
  };

  function handleClickCloseEditDialog(
    maintenanceEngineer: {
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      password: string; //Password change is not yet supported
      role: string;
    } | null
  ) {
    if (maintenanceEngineer) {
      performRequestWithRetry(
        `${BACKEND_APP_URL}/api/maintenance-engineers/${maintenanceEngineer.id}`,
        "PUT",
        {
          id: maintenanceEngineer.id,
          first_name: maintenanceEngineer.first_name,
          last_name: maintenanceEngineer.last_name,
          password: maintenanceEngineer.password,
          email: maintenanceEngineer.email,
        },
        user,
        auth.logout
      )
        .then((response) => {
          if (response && response.status === 200) {
            toast.success("Maintenance Engineer edited successfully", {
              theme: "dark",
            });
            let new_rows = maintenanceEngineers.map((row) => {
              if (row.id === maintenanceEngineer.id) {
                row.first_name = maintenanceEngineer.first_name;
                row.last_name = maintenanceEngineer.last_name;
                row.email = maintenanceEngineer.email;
                row.role = maintenanceEngineer.role;
              }
              return row;
            });
            setMaintenanceEngineers(new_rows);
          } else {
            toast.error("Failed to edit maintenance engineer", {
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.response.data.message === "Email already exists.") {
            toast.error("Email already exists.");
          } else {
            toast.error("Failed to edit maintenance engineer", {
              theme: "colored",
            });
          }
        });
    }
    setMaintenanceEngineerId("");
    setOpenEditDialog(false);
  }

  return (
    <div>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Stack spacing={6} direction="row">
          <h1>Maintenance Engineers</h1>
          <Button
            variant="outlined"
            onClick={() => {
              handleClickOpenAddDialog();
            }}
          >
            ADD NEW +
          </Button>
        </Stack>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Full Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Created Date</StyledTableCell>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell align="center">Edit</StyledTableCell>
              <StyledTableCell align="center">Delete</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {maintenanceEngineers.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.first_name} {row.last_name}
                </StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>
                  {new Date(row.created_at).toLocaleDateString()}
                </StyledTableCell>
                <StyledTableCell>{row.role}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => {
                      handleClickOpenEditDialog(row.id);
                    }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      handleClickOpenDeleteDialog(row.id);
                    }}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <ConfirmDeleteDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
        />

        <AddNewMaintenanceEngineerFormDialog
          open={openAddDialog}
          onClose={handleCloseAddDialog}
        />

        {currentMaintenanceEngineer && (
          <EditMaintenanceEngineerFormDialog
            open={openEditDialog}
            onClose={handleClickCloseEditDialog}
            maintenanceEngineer={currentMaintenanceEngineer}
          />
        )}
      </div>
    </div>
  );
}
