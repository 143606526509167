import { Route, Routes, useNavigate } from "react-router-dom";

import routeComponents from "./RouteComponents";
import { useEffect, useState } from "react";
import {
  ADMIN,
  CHURCH_MAINTENANCE_ENGINEER,
  CHURCH_MAINTENANCE_ENGINEERS,
  CHURCH_MANAGER,
  COMMENTS,
  DRONE_OPERATOR,
  GALLERY,
  LANDING,
  LOGIN,
  MANAGER,
  CREATE_ANNOTAIONS,
  BM_REVIEW_REQUESTS,
  ROLE_ADMIN,
  ROLE_BUSINESSMANAGER,
  ROLE_MAINTENANCEENGINEER,
  ROLE_CHURCHMANAGER,
  ROLE_DRONEOPERATOR,
  SIGNUP,
  VIEW_DRONE_IMAGES,
  BM_DRONE_OPERATOR_ASSIGN,
  UPLOAD_IMAGES,
  PAYMENT_SUCCESS,
  PAYMENT_CANCEL,
  WORKSHOP_ENGINEER,
  FOUNDERS,
} from "../constants";
import Login from "../components/public/LoginComponent/LoginComponent";
import SignUp from "../components/public/SignupComponent/SignupComponent";
import LandingPage from "../components/public/LandingComponent/LandingPage";
import { useAuth } from "../hooks/useAuth";
import MainComponent from "../components/public/MainComponent/Main";
import { useTheme } from "../ThemeProviderWrapper";
import WorkshopEngineerComponent from "../components/public/WorkshopEngineerComponent/WorkshopEngineerComponent";
import Founders from "../components/public/Founders/Founders";

const publicRoutes = [
  { path: LOGIN, component: <Login /> },
  { path: SIGNUP, component: <SignUp /> },
  { path: WORKSHOP_ENGINEER, component: <WorkshopEngineerComponent /> },
  { path: LANDING, component: <LandingPage /> },
  { path: FOUNDERS, component: <Founders /> },
];

const adminRoutes = [{ path: "", component: routeComponents.adminHome() }];
const businessManagerRoutes = [
  { path: "", component: routeComponents.businessManagerHome() },
  {
    path: BM_REVIEW_REQUESTS,
    component: routeComponents.businessManagerReviewRequest(),
  },
  {
    path: VIEW_DRONE_IMAGES,
    component: routeComponents.businessManagerViewDroneImages(),
  },
  {
    path: CREATE_ANNOTAIONS,
    component: routeComponents.businessManagerCreateAnnotations(),
  },
  {
    path: BM_DRONE_OPERATOR_ASSIGN,
    component: routeComponents.businessManagerDroneOperatorAssign(),
  },
];
const churchManagerRoutes = [
  { path: "", component: routeComponents.churchManagerHome() },
  { path: GALLERY, component: routeComponents.gallery() },
  { path: COMMENTS, component: routeComponents.comments() },
  {
    path: CHURCH_MAINTENANCE_ENGINEERS,
    component: routeComponents.maintananceEngineers(),
  },
  { path: PAYMENT_SUCCESS, component: routeComponents.paymentSuccess() },
  { path: PAYMENT_CANCEL, component: routeComponents.paymentCancel() },
];
const droneOperatorRoutes = [
  { path: "", component: routeComponents.droneOperatorHome() },
];
const churchMaintenanceEngineerRoutes = [
  { path: "", component: routeComponents.churchMaintenanceEngineerHome() },
  { path: GALLERY, component: routeComponents.mEGallery() },
  { path: UPLOAD_IMAGES, component: routeComponents.uploadImages() },
];

const AppRouter = () => {
  const { isDarkMode, toggleTheme } = useTheme();
  const userContext = useAuth();
  const [protectedRoutes, setProtectedRoutes] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userRole, setUserRole] = useState("");
  const [userRoute, setUserRoute] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!userContext.user) {
      // Check if current page is not one of the allowed pages
      if (
        window.location.pathname !== `/${SIGNUP}` &&
        window.location.pathname !== `/${LOGIN}` &&
        window.location.pathname !== `/${WORKSHOP_ENGINEER}` &&
        window.location.pathname !== `/${FOUNDERS}`
      ) {
        navigate(LANDING);
      }
    } else if (userContext.user.role) {
      setUserRole(userContext.user.role);
    }
  }, [userContext.user, navigate]);

  useEffect(() => {
    initPrivateRoutes();
  }, [userRole]); // Add isDarkMode if it affects routes

  const initPrivateRoutes = () => {
    if (userRole === ROLE_ADMIN) {
      console.log("router: admin");
      setUserRoute(ADMIN);
      setProtectedRoutes(adminRoutes);
      if (!isDarkMode) {
        toggleTheme();
      }
    }
    if (userRole === ROLE_BUSINESSMANAGER) {
      console.log("router: business-manager");
      setUserRoute(MANAGER);
      setProtectedRoutes(businessManagerRoutes);
      if (!isDarkMode) {
        toggleTheme();
      }
    }
    if (userRole === ROLE_CHURCHMANAGER) {
      console.log("router: church-manager");
      setUserRoute(CHURCH_MANAGER);
      setProtectedRoutes(churchManagerRoutes);
      if (isDarkMode) {
        toggleTheme();
      }
    }
    if (userRole === ROLE_DRONEOPERATOR) {
      console.log("router: drone-operator");
      setUserRoute(DRONE_OPERATOR);
      setProtectedRoutes(droneOperatorRoutes);
      if (!isDarkMode) {
        toggleTheme();
      }
    }
    if (userRole === ROLE_MAINTENANCEENGINEER) {
      console.log("router: maintenance-engineer");
      setUserRoute(CHURCH_MAINTENANCE_ENGINEER);
      setProtectedRoutes(churchMaintenanceEngineerRoutes);
      if (!isDarkMode) {
        toggleTheme();
      }
    }
    if (!userRole) {
      setIsLoading(true);
    }
  };
  const privateRoutes = () => {
    return (
      <Route path={userRoute} element={<MainComponent />}>
        <Route />
        {protectedRoutes.map(({ path, component: Component }: any) => (
          <Route key={path} path={path} element={Component} />
        ))}
      </Route>
    );
  };
  return (
    <Routes>
      {publicRoutes.map(({ path, component: Component }: any) => (
        <Route key={path} path={path} element={Component} />
      ))}
      {privateRoutes()}
      {/* {isLoading ? "" : <Route path="*" element={<NotFound />} />} */}
    </Routes>
  );
};

export default AppRouter;
