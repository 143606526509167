import React, { useEffect, useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { useAuth } from "../hooks/useAuth";
import { performRequestWithRetry } from "./apiRetry";

type UploaderProps = {
  body: {
    files: File[];
    [key: string]: any;
  };
  formKey: string;
  endPoint: string;
  startUpload: boolean;
  setStartUpload: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessfulUpload?: () => void;
  onFailedUpload?: () => void;
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));

const MultipleFileUploader: React.FC<UploaderProps> = ({
  body,
  formKey,
  endPoint,
  startUpload,
  setStartUpload,
  onSuccessfulUpload,
  onFailedUpload,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileSetIndex, setFileSetIndex] = useState(0);
  const [fileSets, setFileSets] = useState<File[][]>([]);
  const [failedFiles, setFailedFiles] = useState<File[]>([]);
  const { files, ...rest } = body;
  const auth = useAuth();
  const user = auth?.user;
  const logout = auth.logout;

  // get the total size of files in file list
  const getTotalSize = (files: File[]) => {
    return files.reduce((acc, file) => acc + file.size, 0);
  };

  useEffect(() => {
    const handleFileChange = async () => {
      setFileSetIndex(0); // Reset file set index
      setUploadProgress(0); // Reset upload progress
      //sort files by size
      files.sort((a, b) => a.size - b.size);

      console.log("files", files);

      // Create sets of files with total size less than 15MB
      const newFileSets: File[][] = [];
      let currentSet: File[] = [];

      for (const file of files) {
        // Check if adding the current file exceeds the limit
        if (getTotalSize([...currentSet, file]) <= 45 * 1024 * 1024) {
          currentSet.push(file); // Add to current set
        } else {
          // If current set is full, push it to newFileSets and start a new set
          if (currentSet.length > 0) {
            newFileSets.push(currentSet);
          }
          currentSet = [file]; // Start a new set with the current file
        }
      }

      // Push the last set if it has any files
      if (currentSet.length > 0) {
        newFileSets.push(currentSet);
      }

      console.log("newFileSets", newFileSets);

      // Update fileSets state
      setFileSets(newFileSets);
    };
    if (files.length > 0) {
      handleFileChange();
    }
  }, [files]);

  useEffect(() => {
    const handleUpload = async () => {
      for (const fileSet of fileSets) {
        const formData = new FormData();
        fileSet.forEach((image) => {
          formData.append(formKey, image);
        });
        // add rest of the parameters of body to formdata
        console.log("rest", rest);

        if (rest) {
          console.log("rest", rest);

          Object.keys(rest).forEach((key) => {
            formData.append(key, rest[key]);
          });
        }

        setFileSetIndex((prev) => prev + 1);

        let attempt = 0;
        const maxRetries = 5; // Maximum number of retries
        let success = false;

        while (attempt < maxRetries && !success) {
          try {
            await performRequestWithRetry(
              endPoint,
              "POST",
              formData,
              user,
              logout,
              "multipart/form-data",
              (progress: number) => {
                setUploadProgress(progress);
              }
            );
            success = true; // Set success to true if upload is successful
          } catch (error: any) {
            if (
              error.code === "ERR_NETWORK" ||
              error.response.data.message === "Unauthorized!"
            ) {
              attempt++;
              const delay = Math.pow(2, attempt) * 100; // Exponential backoff
              console.log(
                `Attempt ${attempt} failed. Retrying in ${delay} ms...`
              );
              await new Promise((resolve) => setTimeout(resolve, delay));
            } else {
              console.error("Upload failed:", error);
              break; // Break out of the retry loop for other errors
            }
          }
        }

        if (!success) {
          console.error("Max retries exceeded for this file set.");
          setFailedFiles((prev) => [...prev, ...fileSet]);
          if (onFailedUpload) {
            onFailedUpload();
          }

          // You can handle failure here, such as notifying the user or logging the error
        }
      }
      setStartUpload(false);
      if (failedFiles.length === 0 && onSuccessfulUpload) {
        onSuccessfulUpload();
      }

      // Reset progress after all uploads are complete
      setUploadProgress(0);
    };

    if (startUpload) {
      handleUpload();
    }
  }, [startUpload]);

  return (
    <>
      <div>
        {/* <button onClick={handleUpload}>Upload</button> */}
        {uploadProgress > 0 && (
          <>
            <span>
              File uploading Step {fileSetIndex} out of {fileSets.length} steps
            </span>
            <LinearProgressWithLabel value={uploadProgress} />
          </>
        )}
        {
          // Display failed files
          failedFiles.length > 0 && (
            <div>
              <h3>Failed Files:</h3>
              {failedFiles.map((file, index) => (
                <>
                  <img
                    width={300}
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                  />
                  <br />
                </>
              ))}
            </div>
          )
        }
      </div>
    </>
  );
};

export default MultipleFileUploader;

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
