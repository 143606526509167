import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Button,
  Link,
  Typography,
  CssBaseline,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import {
  APP_NAME,
  BACKEND_APP_URL,
  LOGIN,
  ROLE_CHURCHMANAGER,
} from "../../../constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bg2 from "../../../assets/images/bg5.webp";
import axios from "axios";
import { useTheme } from "../../../ThemeProviderWrapper";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import NavBar from "../LandingComponent/NavBar";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 30,
  borderRadius: "10px",
  background: "linear-gradient(to right, #4f5b62, #1a1a1a)",
  width: "40vw",
});

const TextFieldStyled = styled(TextField)({
  marginTop: "10px",
  marginBottom: "10px",
  width: "100%",
});

const ButtonStyled = styled(Button)({
  background: "linear-gradient(to right, #ffc051, #c66900)",
  marginTop: "20px",
  width: "100%",
  borderRadius: 10,
});

interface Inputs {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  re_password: string;
  church_name: string;
  church_address: string;
}

const schema = z
  .object({
    first_name: z.string().min(3),
    last_name: z.string().min(3),
    email: z.string().email(),
    password: z
      .string()
      .min(8)
      .refine((data) => {
        return /[A-Z]/.test(data);
      }, "Password must contain at least one uppercase letter.")
      .refine((data) => {
        return /[a-z]/.test(data);
      }, "Password must contain at least one lowercase letter.")
      .refine((data) => {
        return /\d/.test(data);
      }, "Password must contain at least one number.")
      .refine((data) => {
        return /[^A-Za-z0-9]/.test(data);
      }, "Password must contain at least one special character."),
    re_password: z.string().min(8),
    church_name: z.string().min(3),
    church_address: z.string().min(3),
  })
  .refine(
    (data) => {
      return data.password === data.re_password;
    },
    { message: "Passwords do not match.", path: ["re_password"] }
  );

function SignUp() {
  const { isDarkMode, toggleTheme } = useTheme();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      re_password: "",
      church_name: "",
      church_address: "",
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isDarkMode) {
      toggleTheme();
    }
  }, [isDarkMode, toggleTheme]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);

    try {
      setIsLoading(true);

      const churchManager = {
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        password: data.password,
        role: ROLE_CHURCHMANAGER,
        church_name: data.church_name,
        church_address: data.church_address,
        created_at: new Date().toISOString(),
      };

      // Make the API call
      const response = await axios.post(
        `${BACKEND_APP_URL}/api/auth/signup`,
        churchManager
      );

      // Handle the response
      if (response.status === 200) {
        // Signup successful
        toast.success("Signup successful!");
        setTimeout(() => {
          navigate(`/${LOGIN}`);
        }, 1000);
      } else {
        // Signup failed
        toast.error("Signup failed. Please try again.");
      }
    } catch (error: any) {
      // Handle any errors
      if (error.response.data.message === "Failed! Email is already in use!") {
        toast.error("Email already exists.");
      } else {
        toast.error("An error occurred. Please try again.");
      }
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <NavBar />
      <CssBaseline />
      <Box
        sx={{
          backgroundImage: `url(${bg2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ToastContainer />

        <FormContainer
          sx={{
            width: { xs: "80vw", md: "50vw" },
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            {APP_NAME} - Church Signup
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display="flex"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: 0, md: 2 },
              }}
            >
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <TextFieldStyled
                    label="First Name"
                    helperText={errors.first_name?.message}
                    placeholder="First Name"
                    error={errors.first_name !== undefined}
                    {...field}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <TextFieldStyled
                    label="Last Name"
                    helperText={errors.last_name?.message}
                    placeholder="Last Name"
                    error={errors.last_name !== undefined}
                    {...field}
                    variant="outlined"
                  />
                )}
              />
            </Box>
            <Box
              display="flex"
              sx={{
                flexDirection: { xs: "column", md: "row" },
                gap: { xs: 0, md: 2 },
              }}
            >
              <Controller
                name="church_name"
                control={control}
                render={({ field }) => (
                  <TextFieldStyled
                    label="Church Name"
                    placeholder="Church Name"
                    helperText={errors.church_name?.message}
                    error={errors.church_name !== undefined}
                    {...field}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="church_address"
                control={control}
                render={({ field }) => (
                  <TextFieldStyled
                    label="Church Address"
                    placeholder="Church Address"
                    helperText={errors.church_address?.message}
                    error={errors.church_address !== undefined}
                    {...field}
                    variant="outlined"
                  />
                )}
              />
            </Box>

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextFieldStyled
                  label="Email"
                  helperText={errors.email?.message}
                  placeholder="Email"
                  error={errors.email !== undefined}
                  {...field}
                  variant="outlined"
                  fullWidth
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextFieldStyled
                  label="Password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  helperText={errors.password?.message}
                  error={errors.password !== undefined}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                />
              )}
            />

            <Controller
              name="re_password"
              control={control}
              render={({ field }) => (
                <TextFieldStyled
                  label="Re-Password"
                  placeholder="Re-Password"
                  type={showPassword ? "text" : "password"}
                  helperText={errors.re_password?.message}
                  error={errors.re_password !== undefined}
                  {...field}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                />
              )}
            />

            <ButtonStyled
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
            >
              {isLoading ? <CircularProgress size={24} /> : "SIGNUP"}
            </ButtonStyled>
          </form>
          <Typography variant="body2" sx={{ mt: 2 }}>
            Already have an account? <Link href={`/${LOGIN}`}>Log In</Link>
          </Typography>
        </FormContainer>
      </Box>
    </>
  );
}

export default SignUp;
