import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Button } from "@mui/material";
import { MenuItem, TextField, Typography } from "@mui/material";
import { ROLE_MAINTENANCEENGINEER } from "../../constants";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { useState } from "react";

interface Inputs {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  role: string;
}

const schema = z.object({
  first_name: z.string().min(3, "First name should have at least 3 characters"),
  last_name: z.string().min(3, "Last name should have at least 3 characters"),
  email: z.string().email(),
  password: z.string().optional(),
  role: z.enum([ROLE_MAINTENANCEENGINEER]),
});

interface EditMaintenanceEngineerFormDialogProps {
  open: boolean;
  onClose: (
    maintenanceEngineer: {
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      password: string;
      role: string;
    } | null
  ) => void;
  maintenanceEngineer:
    | {
        id: string;
        first_name: string;
        last_name: string;
        email: string;
        password: string;
        created_at: string;
        role: string;
      }
    | undefined;
}

const roles = [
  {
    value: ROLE_MAINTENANCEENGINEER,
    label: "Maintenance Engineer",
  },
];

export default function EditMaintenanceEngineerFormDialog(
  props: EditMaintenanceEngineerFormDialogProps
) {
  const { open, onClose } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const {
    handleSubmit,
    setError,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      first_name: props.maintenanceEngineer?.first_name,
      last_name: props.maintenanceEngineer?.last_name,
      email: props.maintenanceEngineer?.email,
      role: props.maintenanceEngineer?.role,
    },
  });

  console.log(errors);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const first_name = data.first_name;
    const last_name = data.last_name;
    const email = data.email;
    const password = data.password;
    const role = data.role;

    if (password && password !== "") {
      // validate password

      if (password.length < 8) {
        setError("password", {
          type: "manual",
          message: "Password must be at least 8 characters long.",
        });
        return;
      }
      if (!/[A-Z]/.test(password)) {
        setError("password", {
          type: "manual",
          message: "Password must contain at least one uppercase letter.",
        });
        return;
      }
      if (!/[a-z]/.test(password)) {
        setError("password", {
          type: "manual",
          message: "Password must contain at least one lowercase letter.",
        });
        return;
      }
      if (!/\d/.test(password)) {
        setError("password", {
          type: "manual",
          message: "Password must contain at least one number.",
        });
        return;
      }
      if (!/[^A-Za-z0-9]/.test(password)) {
        setError("password", {
          type: "manual",
          message: "Password must contain at least one special character.",
        });
        return;
      }
    }

    const maintenanceEngineer = {
      id: props.maintenanceEngineer?.id as string,
      first_name: first_name as string,
      last_name: last_name as string,
      email: email as string,
      password: password as string,
      role: role as string,
    };
    onClose(maintenanceEngineer);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        onClose(null);
      }}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle variant="h4">Edit Maintenance Engineer</DialogTitle>
      <DialogContent>
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          First Name
        </Typography>
        <Controller
          name="first_name"
          control={control}
          defaultValue={props.maintenanceEngineer?.first_name}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="first_name"
              type="text"
              fullWidth
              variant="outlined"
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
            />
          )}
        />

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Last Name
        </Typography>
        <Controller
          name="last_name"
          control={control}
          defaultValue={props.maintenanceEngineer?.last_name}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="last_name"
              type="text"
              fullWidth
              variant="outlined"
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
            />
          )}
        />

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Email
        </Typography>

        <Controller
          name="email"
          control={control}
          defaultValue={props.maintenanceEngineer?.email}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="email"
              type="email"
              fullWidth
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Password
        </Typography>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              margin="dense"
              id="password"
              type={showPassword ? "text" : "password"}
              fullWidth
              variant="outlined"
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Role
        </Typography>
        <Controller
          name="role"
          control={control}
          defaultValue={props.maintenanceEngineer?.role}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="role"
              select
              name="role"
              // label="Role"
              fullWidth
              variant="outlined"
            >
              {roles.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </DialogContent>
      <DialogActions style={{ padding: "20px" }}>
        <Button
          onClick={() => {
            onClose(null);
          }}
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>
        <Button type="submit" variant="outlined" size="large">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
