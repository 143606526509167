import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Button } from "@mui/material";
import { MenuItem, TextField, Typography } from "@mui/material";
import { ROLE_BUSINESSMANAGER, ROLE_DRONEOPERATOR } from "../../constants";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

interface AddNewManagerFormDialogProps {
  open: boolean;
  onClose: (
    manager: {
      first_name: string;
      last_name: string;
      email: string;
      password: string;
      role: string;
    } | null
  ) => void;
}

interface Inputs {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  role: string;
}

const schema = z
  .object({
    first_name: z
      .string()
      .min(3, "First name must be at least 3 characters long."),
    last_name: z
      .string()
      .min(3, "Last name must be at least 3 characters long."),
    email: z.string().email(),
    password: z
      .string()
      .min(8)
      .refine((data) => {
        return /[A-Z]/.test(data);
      }, "Password must contain at least one uppercase letter.")
      .refine((data) => {
        return /[a-z]/.test(data);
      }, "Password must contain at least one lowercase letter.")
      .refine((data) => {
        return /\d/.test(data);
      }, "Password must contain at least one number.")
      .refine((data) => {
        return /[^A-Za-z0-9]/.test(data);
      }, "Password must contain at least one special character."),
    confirm_password: z.string().min(8),
    role: z.enum([ROLE_BUSINESSMANAGER, ROLE_DRONEOPERATOR]),
  })
  .refine(
    (data) => {
      return data.password === data.confirm_password;
    },
    { message: "Passwords do not match.", path: ["re_password"] }
  );

const roles = [
  {
    value: ROLE_BUSINESSMANAGER,
    label: "Business Manager",
  },
  {
    value: ROLE_DRONEOPERATOR,
    label: "Drone Operator",
  },
];

export default function AddNewManagerFormDialog(
  props: AddNewManagerFormDialogProps
) {
  const { open, onClose } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
    mode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      role: ROLE_BUSINESSMANAGER,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(data);
    const manager = data;
    onClose(manager);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        onClose(null);
      }}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit(onSubmit),
      }}
    >
      <DialogTitle variant="h4">Create New Manager</DialogTitle>
      <DialogContent>
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          First Name
        </Typography>
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              autoFocus
              required
              margin="dense"
              id="first_name"
              type="text"
              fullWidth
              variant="outlined"
              error={!!errors.first_name}
              helperText={errors.first_name?.message}
            />
          )}
        />
        {/* <TextField
          autoFocus
          required
          margin="dense"
          id="first_name"
          name="first_name"
          type="text"
          fullWidth
          variant="outlined"
        /> */}
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Last Name
        </Typography>
        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="last_name"
              type="text"
              fullWidth
              variant="outlined"
              error={!!errors.last_name}
              helperText={errors.last_name?.message}
            />
          )}
        />
        {/* <TextField
          required
          margin="dense"
          id="last_name"
          name="last_name"
          type="text"
          fullWidth
          variant="outlined"
        /> */}
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Email
        </Typography>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="email"
              type="email"
              fullWidth
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />
        {/* <TextField
          required
          margin="dense"
          id="email"
          name="email"
          // label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
        /> */}
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Password
        </Typography>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="password"
              type={showPassword ? "text" : "password"}
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          )}
        />
        {/* <TextField
          required
          margin="dense"
          id="password"
          name="password"
          // label="Password"
          type="password"
          fullWidth
          variant="outlined"
        /> */}
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Confirm Password
        </Typography>
        <Controller
          name="confirm_password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="confirm_password"
              type={showPassword ? "text" : "password"}
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={!!errors.confirm_password}
              helperText={errors.confirm_password?.message}
            />
          )}
        />
        {/* <TextField
          required
          margin="dense"
          id="confirm_password"
          name="confirm_password"
          // label="Confirm Password"
          type="password"
          fullWidth
          variant="outlined"
        /> */}
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Role
        </Typography>
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              required
              margin="dense"
              id="role"
              select
              // label="Role"
              defaultValue={ROLE_BUSINESSMANAGER}
              // helperText="Please select the role"
              fullWidth
              variant="outlined"
              error={!!errors.role}
              helperText={errors.role?.message}
            >
              {roles.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {/* <TextField
          required
          margin="dense"
          id="role"
          select
          name="role"
          // label="Role"
          defaultValue={ROLE_BUSINESSMANAGER}
          // helperText="Please select the role"
          fullWidth
          variant="outlined"
        >
          {roles.map((option: any) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField> */}
      </DialogContent>
      <DialogActions style={{ padding: "20px" }}>
        <Button
          onClick={() => {
            onClose(null);
          }}
          variant="outlined"
          size="large"
        >
          Cancel
        </Button>
        <Button type="submit" variant="outlined" size="large">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
