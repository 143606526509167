import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import church from "../../assets/images/church_req.webp";
import { AddPhotoAlternate } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import { performRequestWithRetry } from "../../utils/apiRetry";
import { BACKEND_APP_URL } from "../../constants";
import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "../../ThemeProviderWrapper";
import MultipleFileUploader from "../../utils/MultipleFileUploader";

export default function DroneOperatorHomeComponent() {
  const [requests, setRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [startUpload, setStartUpload] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState("");
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const user = auth?.user;
  const { isDarkMode, toggleTheme } = useTheme();
  useEffect(() => {
    if (isDarkMode) {
      toggleTheme();
    }
  }, [isDarkMode, toggleTheme]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const images = e.target.files;
    if (images) {
      // Convert FileList to array
      const filesArray = Array.from(images);
      // add the files to state preserving any previous files
      setFiles([...files, ...filesArray]);
    }
  };

  const onSuccessfulUpload = () => {
    toast.success("Images saved successfully", {
      theme: "dark",
    });
    setFiles([]);
    setStartUpload(false);
    setOpen(false);
  };
  const onFailedUpload = () => {
    toast.error("Failed to save images", {
      theme: "colored",
    });
    setFiles([]);
    setStartUpload(false);
  };

  useEffect(() => {
    setLoading(true);
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/drone-operator/requests/${user.id}`,
      "GET",
      null,
      user,
      auth.logout
    )
      .then((response) => {
        if (response && response.status === 200) {
          setRequests(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Failed to fetch requests", {
          theme: "colored",
        });
        setLoading(false);
      });
  }, [auth.logout, user]);

  return (
    <>
      <ToastContainer />
      <Dialog
        open={open}
        onClose={() => {
          setFiles([]);
          setOpen(false);
        }}
      >
        <DialogTitle>Upload Images</DialogTitle>
        <Box sx={{ m: 2 }}>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper
                  sx={{ p: 2, textAlign: "center", color: "text.secondary" }}
                >
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="contained-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <AddPhotoAlternate />
                    </IconButton>
                    <Typography variant="body1">Upload Images</Typography>
                  </label>
                </Paper>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  {files.length > 0 && (
                    <Typography variant="h6" color="text.primary">
                      {files.length} files selected
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setStartUpload(true);
                  }}
                  fullWidth
                  disabled={files.length === 0}
                >
                  UPLOAD TO GALLERY
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ p: 1 }}>
              <MultipleFileUploader
                endPoint={`${BACKEND_APP_URL}/api/drone-operator/images/${selectedRequestId}`}
                formKey="images"
                setStartUpload={setStartUpload}
                startUpload={startUpload}
                onSuccessfulUpload={onSuccessfulUpload}
                onFailedUpload={onFailedUpload}
                body={{
                  files: files,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Dialog>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4">Assigned Jobs</Typography>
        </Grid>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <Grid item xs={12}>
          <Grid container spacing={5}>
            {requests.map((request: any, idx: number) => (
              <Grid item key={idx} xs={4}>
                <Card>
                  <CardContent
                    sx={{ display: "flex", flexDirection: "row", gap: 5 }}
                  >
                    <Box
                      component="img"
                      src={request?.images ? request?.images[0] : church}
                      alt="random"
                      sx={{
                        maxWidth: 150,
                        maxHeight: 150,
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Typography
                        variant="h5"
                        color="text.primary"
                        sx={{ fontWeight: 600 }}
                      >
                        {request?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.primary"
                        sx={{ fontWeight: 500 }}
                      >
                        {request?.email}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.primary"
                        sx={{ fontWeight: 500 }}
                      >
                        {request?.address}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ width: 150, mt: 3 }}
                        onClick={() => {
                          setSelectedRequestId(request._id);
                          setOpen(true);
                        }}
                      >
                        Upload Images
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
