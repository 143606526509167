import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "../../ThemeProviderWrapper";

import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";

import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import AddNewManagerFormDialog from "./AddNewManagerFormDialog";
import EditManagerFormDialog from "./EditManagerFromDialog";
import { BACKEND_APP_URL } from "../../constants";
import { useAuth } from "../../hooks/useAuth";
import { toast, ToastContainer } from "react-toastify";
import { performRequestWithRetry } from "../../utils/apiRetry";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AdminHomeComponent() {
  const { isDarkMode, toggleTheme } = useTheme();

  useEffect(() => {
    if (isDarkMode) {
      toggleTheme();
    }
  }, [isDarkMode, toggleTheme]);

  const [managers, setManagers] = useState<
    {
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      role: string;
      created_at: string;
    }[]
  >([]);
  const [managerId, setManagerId] = useState("");
  const [currentManager, setCurrentManager] = useState<{
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    created_at: string;
  } | null>(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updated, setUpdated] = useState(false);

  const auth = useAuth();
  const user = auth.user;
  const logout = auth.logout;
  const navigate = useNavigate();

  useEffect(() => {
    performRequestWithRetry(
      `${BACKEND_APP_URL}/api/managers`,
      "GET",
      null,
      user,
      logout
    )
      .then((response) => {
        if (response) {
          const managers = response.data;
          setManagers(managers);
        } else {
          auth.logout();
          navigate("/login");
        }
      })
      .catch((error) => {
        toast.error("Failed to fetch Users");
      });
  }, [auth, logout, navigate, updated, user]);

  const handleClickOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = (
    manager: {
      first_name: string;
      last_name: string;
      email: string;
      password: string;
      role: string;
    } | null
  ) => {
    if (manager) {
      performRequestWithRetry(
        `${BACKEND_APP_URL}/api/managers`,
        "POST",
        manager,
        user,
        logout
      )
        .then((response) => {
          if (response?.status === 201) {
            toast.success("Manager added successfully");
            setUpdated(!updated);
          }
        })
        .catch((error) => {
          if (error.response.data.message === "Email already exists.") {
            toast.error("Email already exists.");
          } else {
            toast.error("Failed to add manager");
          }
        });
    }
    setOpenAddDialog(false);
  };

  const handleClickOpenDeleteDialog = (manager_id: string) => {
    setManagerId(manager_id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = (agree: boolean) => {
    if (agree) {
      performRequestWithRetry(
        `${BACKEND_APP_URL}/api/managers/${managerId}`,
        "DELETE",
        null,
        user,
        logout
      ).then((response) => {
        if (response) {
          toast.success("Manager deleted successfully");
          let new_rows = managers.filter((row) => row.id !== managerId);
          setManagers(new_rows);
        } else {
          toast.error("Failed to delete manager");
        }
      });
    }
    setManagerId("");
    setOpenDeleteDialog(false);
  };

  const handleClickOpenEditDialog = (manager_id: string) => {
    setManagerId(manager_id);
    setCurrentManager(
      managers.find((manager) => manager.id === manager_id) || null
    );
    setOpenEditDialog(true);
  };

  function handleClickCloseEditDialog(
    manager: {
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      password: string; //Password change is not yet supported
      role: string;
    } | null
  ) {
    if (manager) {
      performRequestWithRetry(
        `${BACKEND_APP_URL}/api/managers/${manager.id}`,
        "PUT",
        {
          id: manager.id,
          first_name: manager.first_name,
          last_name: manager.last_name,
          email: manager.email,
          password: manager.password,
          role: manager.role,
        },
        user,
        logout
      )
        .then((response) => {
          if (response) {
            let new_rows = managers.map((row) => {
              if (row.id === manager.id) {
                row.first_name = manager.first_name;
                row.last_name = manager.last_name;
                row.email = manager.email;
                row.role = manager.role;
              }
              return row;
            });
            setManagers(new_rows);
            if (response.status === 200) {
              toast.success("User edited successfully");
              setManagerId("");
              setCurrentManager(null);
            } else {
              toast.error("Failed to edit user");
              setManagerId("");
              setCurrentManager(null);
            }
          } else {
            console.error("Error: Failed to edit user");
            toast.error("Failed to edit user");
            setManagerId("");
            setCurrentManager(null);
          }
        })
        .catch((error) => {
          if (error.response.data.message === "Email already exists.") {
            toast.error("Email already exists.");
          } else {
            toast.error("Failed to edit user");
          }
          setManagerId("");
          setCurrentManager(null);
        });
    }
    setManagerId("");
    setCurrentManager(null);
    setOpenEditDialog(false);
  }

  return (
    <div>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Stack spacing={6} direction="row">
          <h1>Managers</h1>
          <Button
            variant="outlined"
            onClick={() => {
              handleClickOpenAddDialog();
            }}
          >
            ADD NEW +
          </Button>
        </Stack>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Full Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Created Date</StyledTableCell>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell align="center">Edit</StyledTableCell>
              <StyledTableCell align="center">Delete</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {managers.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                  {row.first_name} {row.last_name}
                </StyledTableCell>
                <StyledTableCell>{row.email}</StyledTableCell>
                <StyledTableCell>{row.created_at}</StyledTableCell>
                <StyledTableCell>{row.role}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => {
                      handleClickOpenEditDialog(row.id);
                    }}
                  >
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      handleClickOpenDeleteDialog(row.id);
                    }}
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>
        <ConfirmDeleteDialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
        />

        <AddNewManagerFormDialog
          open={openAddDialog}
          onClose={handleCloseAddDialog}
        />

        {currentManager && (
          <EditManagerFormDialog
            open={openEditDialog}
            onClose={handleClickCloseEditDialog}
            manager={currentManager}
          />
        )}
      </div>
    </div>
  );
}
